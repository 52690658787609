import React, { useEffect, useRef, useState } from "react";
import { navigate } from "gatsby";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import { PAGES } from "../helpers/constants";
import { ERRORS } from "../helpers/errors";
import {
  BOTTOM_OPTIONS_FORM,
  CENTER_OPTIONS_FORM,
  // DEALS_BANNER_CONTAINER_PROPS,
} from "../components/main/index.content";
import { log } from "../helpers";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import HomeIntro from "../components/main/HomeIntro";
import ChromeStoreRating from "../components/main/ChromeStoreRating";
import Brands from "../components/common/Brands";
import RatingOverview from "../components/main/RatingOverview";
import Testimonials from "../components/main/Testimonials";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import ProValue from "../components/main/ProValue";
import Partners from "../components/main/Partners";
import Confession from "../components/main/Confession";
import ProInstall from "../components/main/ProInstall";
import Support from "../components/main/Support";
import SuccessStories from "../components/main/SuccessStories";
import Guides from "../components/main/Guides";
import Idea from "../components/main/Idea";
// import DealsBannerContainer from "../containers/common/DealsBannerContainer";
// import DealsStaticBannerContainer from "../containers/common/DealsStaticBannerContainer";
// import SimpleDeals from "../components/common/SimpleDeals";
// import EmptySpace from "../components/main/EmptySpace";
import FormContainer from "../containers/common/forms/FormContainer";
import FormPage from "../components/common/Forms/FormPage";
import "../components/main/index.scss";

const PgEMain = () => {
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  // const [isShowStaticBanner, setIsShowStaticBanner] = useState(true);
  // const [isDealsHidesPrice, setIsDealsHidesPrice] = useState(false);
  const [isScrolledPartners, setIsScrolledPartners] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  const pricingRef = useRef(null);
  const homeIntroRef = useRef(null);
  const closeYoutubeModal = () => setUrlYoutubeModal("");

  useEffect(() => {
    const handleHashChange = () => {
      try {
        if (typeof window !== `undefined`) {
          if (window.location.hash === "#partners") {
            if (document.readyState === "complete") {
              navigate("#partners");
              setIsScrolledPartners(true);
            }
          }
        }
      } catch (error) {
        log(error, ERRORS.URL_ERRORS.ERROR_PARSING_URL_PARAMETERS, true);
      }
    };
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  useEffect(() => {
    if (isScrolledPartners) {
      setTimeout(() => {
        const partners = document.querySelector("#partners");
        partners?.getBoundingClientRect().top !== 0 &&
          partners?.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
      }, 1000);
    }
  }, [isScrolledPartners]);

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  return (
    <Layout
      customClass="PgEM"
      isShowYoutubeModal={isShowYoutubeModal}
      tariffsElement={pricingRef}
      closeYoutubeModal={closeYoutubeModal}
      urlYoutubeModal={urlYoutubeModal}
      // isSimpleTopPanel
      isShowLanguageSwitcher
    >
      <Seo
        title="AMZScout | Amazon Product Research Tool & FBA Sellers Software"
        description="Use AMZScout and make Amazon product research easy with accurate research tools and seller software: AMZScout PRO Chrome Extension, Product Database, Keyword Search and more. Analyze niches and find the right product to make money. Start your free trial."
        page=""
        manifest="browserconfig-index.xml"
        ogImg={OgImg}
      />
      <HomeIntro homeIntroRef={homeIntroRef} />
      {/*<DealsStaticBannerContainer*/}
      {/*  isShowStaticBanner={isShowStaticBanner}*/}
      {/*  setIsShowStaticBanner={setIsShowStaticBanner}*/}
      {/*  homeIntroRef={homeIntroRef}*/}
      {/*  {...DEALS_BANNER_CONTAINER_PROPS}*/}
      {/*>*/}
      {/*  <SimpleDeals hideCloseButton isStaticDeals />*/}
      {/*</DealsStaticBannerContainer>*/}
      <ChromeStoreRating />
      <Brands />
      <RatingOverview />
      <CustomizableContainer
        pricingRef={pricingRef}
        customClass="PgEM-Pricing"
        // setIsDealsHidesPrice={setIsDealsHidesPrice}
      />
      <Testimonials setUrlYoutubeModal={setUrlYoutubeModal} />
      <ProValue />
      <FormContainer page={PAGES.MAIN_PAGE}>
        <FormPage option={CENTER_OPTIONS_FORM} />
      </FormContainer>
      <Partners />
      <Confession />
      <ProInstall />
      <Support />
      <SuccessStories />
      <Idea />
      <Guides />
      <FormContainer page={PAGES.MAIN_PAGE}>
        <FormPage option={BOTTOM_OPTIONS_FORM} />
      </FormContainer>
      {/*<EmptySpace isShow={process.env.DEALS_SECTION} />*/}
      {/*<DealsBannerContainer*/}
      {/*  alwaysShowDeals*/}
      {/*  multipleBannerOptions*/}
      {/*  isShowStaticBanner={isShowStaticBanner}*/}
      {/*  isDealsHidesPrice={isDealsHidesPrice}*/}
      {/*  {...DEALS_BANNER_CONTAINER_PROPS}*/}
      {/*>*/}
      {/*  <SimpleDeals hideCloseButton />*/}
      {/*</DealsBannerContainer>*/}
    </Layout>
  );
};
export default PgEMain;
