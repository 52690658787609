import "./src/assets/scss/global.scss";
import React from "react";
import locationController from "./src/controllers/common/locationController";
import extraLocalStyleController from "./src/controllers/common/extraLocalStyleController";
import updaterRedirectController from "./src/controllers/services/updaterRedirectController";
import MediaAuthProvider from "./src/store/providers/MediaAuthProvider";

export const onClientEntry = () => {
  locationController();
  process.env.GATSBY_UPDATER_ENV && updaterRedirectController();
};

export const onInitialClientRender = () => {
  extraLocalStyleController();
};
export const shouldUpdateScroll = ({ routerProps, getSavedScrollPosition }) => {
  console.log("!!!!!!routerProps", routerProps);
  console.log("!!!!!!!getSavedScrollPosition", getSavedScrollPosition);
  // const hash = routerProps.location.hash;
  // if (routerProps.location.pathname === "/" && hash === "#partners") {
  //
  //   setTimeout(() => {
  //     // if (location.hash) {
  //     // If hash (`/#something`), smooth scroll to that position
  //     const item = document.querySelector(`${hash}`);
  //     const rect = item.getBoundingClientRect();
  //     const offset = window.pageYOffset || document.documentElement.scrollTop; // Получаем текущее смещение страницы
  //     window.scrollTo({
  //       top: rect.top + offset, // Рассчитываем позицию элемента относительно верхней части страницы
  //       // behavior: 'smooth' // Указываем плавную прокрутку
  //     });
  //   }, 0);
  //   return false;
  // }
  return true;
};

export const wrapRootElement = ({ element }) => {
  return (
    <MediaAuthProvider>{element}</MediaAuthProvider>
  );
};
